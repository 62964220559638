import { render } from "./SelectedItemCondition.vue?vue&type=template&id=c1866f80"
import script from "./SelectedItemCondition.vue?vue&type=script&lang=ts"
export * from "./SelectedItemCondition.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QItemLabel,QCheckbox,QBtn});
