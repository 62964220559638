<template>
  <div class="process-full fixed fullscreen" v-if="loading">
    <q-spinner-pie color="white" size="5em" class="q-ma-auto fixed fullscreen" />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class CLoading extends Vue {
  @Prop({ default: false })
  loading!: boolean
}
</script>
<style lang="scss" scoped>
.process-full {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
