
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAnswerItem } from '@/utils/types'
import { ICard } from 'bot-flow-maker/src/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class SelectedItemCondition extends Vue {
  @Prop()
  selected!: ICard

  answerItems: IAnswerItem[] = []

  get answers() {
    this.answerItems = []

    if (this.selected.answers) {
      for (const item of this.selected.answers) {
        this.answerItems.push({
          _id: item.id,
          title: item.title,
          label: item.title,
          isSelect: false,
        })
      }
    }

    return this.answerItems
  }

  get selectedAnswers() {
    return this.answerItems.filter((item) => item.isSelect === true)
  }

  @Emit('update:onSelect')
  onSelect() {
    const selectedAnswerIds: string[] = []
    for (const item of this.selectedAnswers) {
      selectedAnswerIds.push(item._id ?? '')
    }
    return selectedAnswerIds
  }
}
