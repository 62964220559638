<template>
  <q-menu :anchor="menuAnchor" :self="menuSelf">
    <SelectedItemCondition :selected="selected" @update:onSelect="onSelect" />
  </q-menu>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import SelectedItemCondition from '@/components/scenarios/ui/SelectedItemCondition.vue'
import { ICard } from 'bot-flow-maker/src/types'

@Options({
  components: { SelectedItemCondition },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class ScenarioConditionMenu extends Vue {
  @Prop()
  selected!: ICard

  @Prop()
  cardType!: string

  @Prop({ default: 'top right' })
  menuAnchor!: string

  @Prop({ default: 'top start' })
  menuSelf!: string

  @Emit('update:onSelect')
  onSelect(selectedAnswerIds) {
    return { cardType: this.cardType, uniqueId: this.selected.uniqueId, selectedAnswerIds }
  }
}
</script>
