
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class CLoading extends Vue {
  @Prop({ default: false })
  loading!: boolean
}
