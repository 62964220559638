import { render } from "./CLoading.vue?vue&type=template&id=1cc3005a&scoped=true"
import script from "./CLoading.vue?vue&type=script&lang=ts"
export * from "./CLoading.vue?vue&type=script&lang=ts"

import "./CLoading.vue?vue&type=style&index=0&id=1cc3005a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1cc3005a"

export default script
import QSpinnerPie from 'quasar/src/components/spinner/QSpinnerPie.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinnerPie});
